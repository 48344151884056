@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-main-green: #14AA4B;
  --color-emphasis-green: #1a8079;
  --color-silver: #dedede;
  --color-gray: #707070;
  --color-coal: #1f1f1f;
  --color-gray: #00000029;
  --color-light-gray: #f0f0f0;
  --color-red-alert: #b11426;
  --color-dark-grey: rgb(16,16,16)
}

html,
body {
  font-family: 'Montserrat', 'sans-serif';
  background-color: var(--color-white);
  color: var(--color-black);
  height: auto;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  margin: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}


:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-main-green: #14AA4B;
  --color-emphasis-green: #1a8079;
  --color-silver: #dedede;
  --color-gray: #707070;
  --color-coal: #1f1f1f;
  --color-gray: #00000029;
  --color-light-gray: #f0f0f0;
  --color-red-alert: #b11426;
  --color-dark-grey: rgb(16,16,16)
}

.styles_flexColumn__2uaoQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_coupon__69I-Z {
  height: 100%;
}

.styles_coupon__69I-Z > section {
  margin-bottom: 50px;
}

.styles_header__D7Cd9 {
  align-items: center;
  background-color: var(--color-dark-grey);
  display: flex;
  flex-direction: column;
  padding: 76px 40px;
  position: relative;
  width: 100%;
}

.styles_stoneCo__31yrC {
  max-width: 106px;
}

.styles_blackFriday__2UKky {
  width: 100%;
  max-width: 650px;
}

.styles_stoneCo__31yrC,
.styles_blackFriday__2UKky {
  margin-bottom: 45px;
}

.styles_icons__1vbC5 {
  width: 100%;
  margin-bottom: 55px;
  max-width: 367px;
}

.styles_wrapper__1cnmU {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
  width: 100%;
}

.styles_box__1yOKX {
  align-items: center;
  background-color: var(--color-white);
  box-shadow: -3px 0px 12px 2px rgba(192, 192, 192, 0.23);
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 861px;
  padding: 50px;
  -webkit-transform: translateY(-90px);
          transform: translateY(-90px);
  margin-bottom: -90px;
}

.styles_title__2ehts {
  color: var(--color-main-green);
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
}

.styles_description__EkZzN {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  max-width: 632px;
}

.styles_title__2ehts,
.styles_description__EkZzN {
  font-style: normal;
  text-align: center;
}

.styles_button__3pqej {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--color-main-green);
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 45px;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.styles_button__3pqej.styles_small__jkCOq {
  font-size: 16px;
  padding: 14px 30px;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-grid-row-align: center;
      align-self: center;
}

.styles_button__3pqej.styles_huge__2D9s4 {
  font-size: 20px;
  padding: 30px;
  font-weight: bold;
}

.styles_button__3pqej:hover{
  background-color: var(--color-emphasis-green);
  cursor: pointer;
}

.styles_button__3pqej:active {
  background-color: var(--color-emphasis-green);
  color: var(--color-silver);
}

.styles_modal__1XamP {
  box-shadow: 0px 0px 12px var(--color-gray);
  background-color: var(--color-white);
  color: var(--color-black);
  max-width: 400px;
  width: 90%;
  margin: auto;
  margin-top: 25vh;
  display: flex;
  height: auto;
  border-radius: 12px;
  outline: none;
}

@media screen and (max-width: 480px) {
  .styles_modal__1XamP {
    margin-top: 18vh;
  }
}

.styles_exitBtn__yknSq {
  align-self: flex-end;
  margin-bottom: 5px;
  font-size: 30px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.styles_buyBtn__1itZC {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-grid-row-align: center;
      align-self: center;
}

.styles_modalContent__2Wk6O {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 32px;
}

.styles_code__3reAy {
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 0 20px 30px;
}

.styles_description__1zaLA {
  text-align: center;
  padding: 0 20px 30px;
}

.styles_couponContent__1dlXc {
  background-color: var(--color-white);
  width: 236px;
  height: 287px;
  color: var(--color-black);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px var(--color-gray);
}

.styles_couponDescription__3lLuv {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.45px;
  align-items: center;
  height: 100px;
  margin: 0px 0px;
}

.styles_couponTitle__-2jj- {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.styles_couponCode__kGxJl {
  font-weight: bold;
  text-align: center;
}

.styles_showMore__2LtGz {
  color: var(--color-emphasis-green);
  cursor: pointer;
}

@media only screen and (min-device-width: 480px) and (max-device-width: 885px) {
  .styles_couponContent__1dlXc {
    height: 265px;
    padding: 8px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .styles_couponContent__1dlXc {
    width: 100%;
    height: 265px;
  }
}

.styles_couponList__28zhC {
  -ms-grid-columns: 1fr 18px 1fr 18px 1fr 18px 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 18px;
  -webkit-column-gap: 18px;
     -moz-column-gap: 18px;
          column-gap: 18px;
  grid-row-gap: 40px;
  row-gap: 40px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  display: -ms-grid;
  display: grid;
  margin: 0 20px 80px;
}

.styles_couponList__28zhC > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.styles_couponList__28zhC > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.styles_couponList__28zhC > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}

.styles_couponList__28zhC > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}

.styles_title__1Q8TM {
  color: var(--color-main-green);
  margin: 80px 0;
  font-size: 40px;
  text-align: center;
  text-transform: capitalize;
}

@media only screen and (min-device-width: 885px) and (max-device-width: 1024px) {
  .styles_couponList__28zhC {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 885px) {
  .styles_couponList__28zhC {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-device-width: 480px) {
  .styles_couponList__28zhC {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.styles_footer__cVV_0 {
  align-items: center;
  background-color: var(--color-dark-grey);
  flex-direction: column;
  display: flex;
  padding: 81px 30px;
  width: 100%;
}

.styles_stone__1OzgT {
  width: 100%;
  max-width: 400px;
}

.styles_content__CHpGn{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 50px;
}

.styles_loginButton__1Cv9S{
  display: flex;
  justify-content: center;
}

.styles_content__1r4H3{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

