:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-main-green: #14AA4B;
  --color-emphasis-green: #1a8079;
  --color-silver: #dedede;
  --color-gray: #707070;
  --color-coal: #1f1f1f;
  --color-gray: #00000029;
  --color-light-gray: #f0f0f0;
  --color-red-alert: #b11426;
  --color-dark-grey: rgb(16,16,16)
}
