@import './colors.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

html,
body {
  font-family: 'Montserrat', 'sans-serif';
  background-color: var(--color-white);
  color: var(--color-black);
  height: auto;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  margin: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

